<template>
  <div>
    <el-row type="flex" justify="space-between">
        <div>Цели:</div>
        <div>A: {{scale.target_a}}</div>
        <div>B: {{scale.target_b}}</div>
      </el-row>
    <el-row type="flex" justify="space-between">
        <div>Выплаты: </div>
        <div>A: {{scale.pay_a}}</div>
        <div>B: {{scale.pay_b}}</div>
    </el-row>
    <div>Факт: {{scale.fact}}</div>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "linear-three-targets-library-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {

  },

  data() {
    return {
      scale:{
        target_a: null,
        target_b: null,
        pay_a: null,
        pay_b: null,
        fact: null,
      },
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  }
}
</script>

<style>

</style>